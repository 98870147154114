import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import SEO from "./seo"
import CloseButton from "./close-button"

export default ({ data }) => {
  const page = data.markdownRemark
  return (
    <>
      <SEO title={page.frontmatter.title} />
      <CloseButton />
      <h1 className="page-title">
        <span dangerouslySetInnerHTML={{ __html: page.frontmatter.title }}></span>
      {page.frontmatter.date &&
        <div css={css`
          color: #b18725;
          font-size: 60%;
        `}>{page.frontmatter.date}</div>
      }</h1>
      <div className={`page-content${page.frontmatter.className ? ' ' + page.frontmatter.className : ''}`} dangerouslySetInnerHTML={{ __html: page.html }} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "D. MMMM YYYY", locale: "de")
        className
      }
    }
  }
`